"use client"
import { useState, useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';
import "@/styles/globals.css";
import { Inter as FontSans } from "next/font/google";
import localFont from "next/font/local";
import { Metadata } from "next";
import { cn } from "@/lib/utils";
import { ThemeProvider } from "@/components/theme-provider";
import { Toaster } from "@/components/ui/toaster";
import { siteConfig } from "@/config/site";
import Chatbot from '@/components/chatbot';
import { GoogleAnalytics } from '@next/third-parties/google';
import { AOSInit } from '@/components/aos-init';
import { TooltipProvider } from '@/components/ui/tooltip';

const fontSans = FontSans({
  subsets: ["latin"],
  variable: "--font-sans",
});

const fontHeading = localFont({
  src: "../assets/fonts/CalSans-SemiBold.woff2",
  variable: "--font-heading",
});

const title = `Build chatbot using OpenAI Assistant - ${siteConfig.name} by Sanket Bagad`;
const description = "Transform your website into a 24/7 customer engagement powerhouse with BotByte AI – where instant support meets seamless interaction!";

// export const metadata: Metadata = {
//   title,
//   description,
//   twitter: {
//     card: "summary_large_image",
//     title,
//     description,
//   },
//   metadataBase: new URL('https://bytebot.vercel.app/'),
//   openGraph: {
//     title: title,
//     description: description,
//     url: new URL('https://bytebot.vercel.app/'),
//     siteName: 'ByteBot AI',
//     type: 'website',
//   },
// };

export default function RootLayout({ children }: { children: React.ReactNode }) {
  

  return (
    <html lang="en" suppressHydrationWarning>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={siteConfig.url} />
        <meta property="og:site_name" content={siteConfig.name} />
        <meta property="og:image" content={siteConfig.ogImage} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <title>{title}</title>
      </head>
      <AOSInit />
      <body
        id='root'
        className={cn(
          "min-h-screen bg-background font-sans antialiased",
          fontSans.variable,
          fontHeading.variable
        )}
      >
          <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
            <TooltipProvider>
              {children}
              <Toaster />
              {process.env.VERCEL_ENV === "production" && <Analytics />}
            </TooltipProvider>
          </ThemeProvider>
        <Chatbot />
      </body>
      <GoogleAnalytics gaId="G-4ZGE0LCGSM" />
    </html>
  );
}
